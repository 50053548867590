import { render, staticRenderFns } from "./PanelConferenceAbout.vue?vue&type=template&id=44d05508"
import script from "./PanelConferenceAbout.vue?vue&type=script&lang=js"
export * from "./PanelConferenceAbout.vue?vue&type=script&lang=js"
import style0 from "./PanelConferenceAbout.vue?vue&type=style&index=0&id=44d05508&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PanelConferenceAbout.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports