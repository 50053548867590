<template>
	<div
		v-if="isBlockShown"
		:class="['panel', 'about_event',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
		]"
	>
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div
						v-if="isHeaderVisible"
						:class="[
							'panel_header',
							isBrand? 'sk' : '',
						]"
					>
						<h2>
							<span>{{ block_title }}</span>
						</h2>
					</div>
				</div>
				<div
					v-if="'conference_program_description' in block.static_content
						&& block.static_content.conference_program_description.ru !== null"
					class="col-12 col-lg-6"
				>
					<div class="event_overview">
						<div v-if="'description' in block.static_content" class="title mb-4">
							{{ block.static_content.description.ru }}
						</div>
						<div v-if="'text' in block.static_content" class="description" v-html="block.static_content.text.ru" />
					</div>
				</div>
				<div v-else class="col-12 col-lg-12">
					<div class="event_overview">
						<div v-if="'description' in block.static_content" class="title mb-4">
							{{ block.static_content.description.ru }}
						</div>
						<div v-if="'text' in block.static_content" class="description" v-html="block.static_content.text.ru" />
					</div>
				</div>
				<div
					v-if="'conference_program_description' in block.static_content && block.static_content.conference_program_description.ru !== null"
					class="col-12 col-lg-5 offset-lg-1"
				>
					<div class="row">
						<div class="col-12 col-sm-7 col-md-6 col-lg-7 order-sm-2">
							<div class="event_brief_image theme-inverse">
								<div
									class="bg-event"
									:style="'background-image:url(' + fetchedData.top_cover_image_url_default + ')'"
								>
									<div class="event_date">
										{{ fetchedData.event_date | moment("DD") }}
									</div>
									<div class="event_month">
										{{ fetchedData.event_date | moment("MMMM") }}
									</div>
									<div
										class="event-title"
									>
										{{ block.static_content.conference_program_background_description.ru }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-5 col-md-6 col-lg-5 order-sm-1">
							<div class="event_brief">
								<div
									v-if="'conference_program_title' in block.static_content && block.static_content.conference_program_title.ru !== null"
									class="title"
								>
									{{ block.static_content.conference_program_title.ru }}
								</div>
								<div v-else class="title">
									{{ $t('program') }} {{ fetchedData.type_titles.title_genitive }}
								</div>
								<p class="description">
									{{ block.static_content.conference_program_description.ru }}
								</p>
								<div
									v-if="'scenario' in block.static_content
										&& block.static_content.scenario !=='pdf'"
								>
									<a v-scroll-to="block.static_content.scenario === 'smart-form' ? '#empty-registration': '#header-registration'" href="#" class="btn btn-primary-outer" @click="setPresentationNeeded">
										<span
											v-if="'button_text' in block.static_content"
										>{{ block.static_content.button_text.ru }}</span>
										<span v-else>{{ $t('download') }}</span>
									</a>
								</div>

								<div v-else>
									<a
										v-if="'program_pdf_url' in block.static_content
											&& block.static_content.program_pdf_url.ru !==null "
										:href="block.static_content.program_pdf_url.ru"
										target="_blank"
										class="btn btn-primary-outer"
									>
										<span
											v-if="'button_text' in block.static_content"
										>{{ block.static_content.button_text.ru }}</span>
										<span v-else>{{ $t('download') }}</span>
									</a>
									<a
										v-else
										:href="fetchedData.pdf_url"
										target="_blank"
										class="btn btn-primary-outer"
									>
										<span
											v-if="'button_text' in block.static_content"
										>{{ block.static_content.button_text.ru }}</span>
										<span v-else>{{ $t('download') }}</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import '@/utils/moment';

import panel from '@/mixins/panel';
import checkIsBlockShown from '~/mixins/checkIsBlockShown';

export default {
	name: 'PanelConferenceAbout',
	mixins: [panel, checkIsBlockShown],
	props: ['content', 'static_content'],
	computed: {
		block_title() {
			if (this.block.block_title) return this.block.block_title;

			const { type } = this.fetchedData;
			const preposition = type === 1 || type === 4 || type === 16 ? this.$t('about-long') : this.$t('about');
			const title = this.fetchedData.type_titles.title_prepositional;
			return `${preposition} ${title}`;
		},
	},
	methods: {
		/**
			 * Зависит от наличия на странице блока panel-smart-form
			 */
		setPresentationNeeded() {
			if (
				typeof this.$parent.$refs['panel-smart-form'] === 'undefined'
				&& 0 in this.$parent.$refs['panel-smart-form']
			) {
				return;
			}

			this.$parent.$refs['panel-smart-form'][0].setPresentationNeeded(true);
		},
	},
};
</script>

<style lang="scss">
	@import "~/assets/styles/components/home/panel-about_program";
</style>

<i18n lang="yaml">
ru:
  about: "О"
  about-long: "Об"
  download: "Скачать"
  program: "Программа"
en:
  about: "About"
  about-long: "About"
  download: "Download"
  program: "Programme"
</i18n>
